import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import useSWR from "swr";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import AddIcon from "@mui/icons-material/Add";
import DescriptionIcon from "@mui/icons-material/Description";
import ListIcon from "@mui/icons-material/List";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import Loader from "../../components/Loader";
import CustomButton from "../../components/CustomButton";
import BillboardDateSelection from "../../components/BillboardDateSelection";
import {
  fetchBillboardsByBrief,
  createCampaignByBriefId,
  getCampaignListAPI,
  createCampaignManually,
  deleteCampaignAPI,
  downloadCampaignPPTAPI
} from "../../apis/campaigns.apis"; //

const AddCampaign = () => {
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [briefId, setBriefId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isBillboardModalOpen, setIsBillboardModalOpen] = useState(false);
  const [billboards, setBillboards] = useState([]);
  const [isLoaderOpen, setisLoaderOpen] = useState(false);

  const {
    data: response,
    error,
    isLoading,
    mutate,
  } = useSWR("/campaigns/list", getCampaignListAPI);

  const handleDelete = async (campaignId) => {
    if (!window.confirm("Are you sure you want to delete this campaign?"))
      return;

    try {
      await deleteCampaignAPI(campaignId); // Call API to delete from DB
      toast.success("Campaign deleted successfully!");
      setCampaigns(campaigns.filter((c) => c.id !== campaignId)); // Update UI
    } catch (error) {
      console.error("Error deleting campaign:", error);
      toast.error("Failed to delete campaign.");
    }
  };

  const handleDownloadPPT = (campaign_id) => {
		setisLoaderOpen(true);

		downloadCampaignPPTAPI(campaign_id)
			.then((v) => {})
			.catch((e) => {
				const msg = e?.response?.data?.message || "Something went wrong!";
				toast.error(msg);
			})
			.finally((v) => {
				setisLoaderOpen(false);
			});
	};

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    setBriefId("");
    setSelectedFile(null);
  };

  const handleBriefIdSubmit = async () => {
    if (!briefId.trim()) return toast.error("Please enter a valid Brief ID.");

    try {
      const response = await fetchBillboardsByBrief(briefId); // Await API response
      console.log(response);

      setBillboards(response.data.billboards || []); // Ensure it's an array
      setIsDialogOpen(false);
      setIsBillboardModalOpen(true);
    } catch (error) {
      console.error("Error fetching billboards:", error);
      toast.error("Failed to fetch billboards.");
    }
  };
  const handleConfirm = async (billboardData) => {
    try {
      let campaignData = { billboards: billboardData };

      // Include briefId only if it's defined
      if (briefId) {
        campaignData.brief_id = briefId;
      }

      if (briefId) {
        await createCampaignByBriefId(campaignData);
      } else {
        await createCampaignManually(campaignData);
      }

      toast.success("Campaign created successfully!");

      setIsBillboardModalOpen(false);
      mutate();
      navigate("/campaignreport");
     
    } catch (error) {
      console.error("Error creating campaign:", error);
      toast.error("Failed to create campaign.");
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file) return;
    if (!file.name.endsWith(".xlsx") && !file.name.endsWith(".xls")) {
      toast.error("Please upload an Excel file (.xlsx or .xls)");
      return;
    }

    setSelectedFile(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
  });

  const handleFileUploadSubmit = async () => {
    if (!selectedFile) {
      toast.error("Please select an Excel file.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // Extract relevant columns
      const headers = jsonData[0]; // First row contains headers
      const billboardIdIndex = headers.findIndex(
        (h) =>
          h.toLowerCase().includes("asset id") ||
          h.toLowerCase().includes("asset id")
      );
      const startDateIndex = headers.findIndex((h) =>
        h.toLowerCase().includes("start date")
      );
      const durationIndex = headers.findIndex((h) =>
        h.toLowerCase().includes("duration")
      );

      if (
        billboardIdIndex === -1 ||
        startDateIndex === -1 ||
        durationIndex === -1
      ) {
        toast.error(
          "Invalid file format. Make sure it contains asset id, start date, and duration."
        );
        return;
      }

      const extractedData = jsonData
        .slice(1)
        .map((row) => ({
          id: row[billboardIdIndex],
          startDate: moment(row[startDateIndex]).format("YYYY-MM-DD"),
          duration: row[durationIndex],
        }))
        .filter((b) => b.id); // Filter out empty rows

      setBillboards(extractedData);
      setIsDialogOpen(false);
      setIsBillboardModalOpen(true);
    };

    reader.readAsArrayBuffer(selectedFile);
  };

  console.log(response);
  return (
    <Box>
      <CustomButton
        variant="contained"
        color="primary"
        sx={{ mb: 1 }}
        startIcon={<AddIcon />}
        onClick={() => {
          setSelectedOption(null);
          setIsDialogOpen(true);
        }}
      >
        Add Campaign
      </CustomButton>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow >
              {[
                "Campaign ID",
                "Brief ID",
                "Start Date",
                "Duration",
                "Created At",
                "Status",
                "Action",
              ].map((header) => (
                <TableCell align = "center" key={header} sx={{ fontWeight: "600" }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {response?.data?.map((campaign) => (
              <TableRow key={campaign.id}>
                <TableCell>{campaign.id}</TableCell>
                <TableCell>
                  {campaign.brief_id === null ? "Manual" : campaign.brief_id}
                </TableCell>
                <TableCell>
                  {moment(campaign.start_date).format("YYYY-MM-DD")}
                </TableCell>
                <TableCell>{campaign.duration} days</TableCell>
                <TableCell>
                  {moment(campaign.start_date).format("YYYY-MM-DD")}
                </TableCell>
                <TableCell>
                  <CustomButton
                    variant="contained"
                    sx={{
                      backgroundColor: campaign.status === 1 ? "green" : "red",
                      color: "white",
                      "&:hover": {
                        backgroundColor:
                          campaign.status === 1 ? "darkgreen" : "darkred",
                      },
                    }}
                  >
                    {campaign.status === 1 ? "Completed" : "Ongoing"}
                  </CustomButton>
                </TableCell>
                <TableCell>
                  <Stack direction="row" >
                    <IconButton color="primary">
                      <ModeEditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDownloadPPT(campaign.id)}>
                      <DownloadForOfflineIcon  />
                    </IconButton>

                    <IconButton
                    onClick={() =>
                      window.open(
                        `/campaignreport/${campaign.id}/graphs`,
                        "_blank"
                      )
                    } >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(campaign.campaign_id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiDialog-paper": { padding: 3, borderRadius: 2 },
        }}
      >
        <DialogTitle sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>
          Add Campaign
        </DialogTitle>
        <DialogContent>
          {!selectedOption ? (
            <>
              <Typography
                variant="body1"
                sx={{ mb: 2, color: "text.secondary" }}
              >
                Choose how you want to add a campaign.
              </Typography>

              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} sm={6}>
                  <Paper
                    elevation={3}
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "0.3s",
                      "&:hover": { bgcolor: "#f5f5f5" },
                    }}
                    onClick={() => handleSelectOption("byBriefId")}
                  >
                    <ListIcon
                      sx={{ fontSize: 40, mb: 1, color: "primary.main" }}
                    />
                    <Typography variant="h6" fontWeight="bold">
                      By Brief ID
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      textAlign="center"
                    >
                      Link billboards using a pre-existing Brief ID.
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Paper
                    elevation={3}
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "0.3s",
                      "&:hover": { bgcolor: "#f5f5f5" },
                    }}
                    onClick={() => handleSelectOption("upload")}
                  >
                    <DescriptionIcon
                      sx={{ fontSize: 40, mb: 1, color: "secondary.main" }}
                    />
                    <Typography variant="h6" fontWeight="bold">
                      Upload Plan Manually
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      textAlign="center"
                    >
                      Upload an Excel file to add billboards manually.
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </>
          ) : selectedOption === "byBriefId" ? (
            <TextField
              fullWidth
              label="Enter Brief ID"
              variant="outlined"
              value={briefId}
              onChange={(e) => setBriefId(e.target.value)}
              sx={{ mt: 2 }}
            />
          ) : (
            <div
              {...getRootProps()}
              style={{
                border: "1px dashed gray",
                padding: 20,
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <input {...getInputProps()} />
              {selectedFile
                ? selectedFile.name
                : "Drag & drop an Excel file or click to select"}
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <CustomButton onClick={() => setIsDialogOpen(false)}>
            Cancel
          </CustomButton>
          {selectedOption === "byBriefId" && (
            <CustomButton onClick={handleBriefIdSubmit}>Add</CustomButton>
          )}
          {selectedOption === "upload" && (
            <CustomButton onClick={handleFileUploadSubmit}>Upload</CustomButton>
          )}
        </DialogActions>
      </Dialog>

      {isBillboardModalOpen && (
        <BillboardDateSelection
          open={isBillboardModalOpen}
          onClose={() => setIsBillboardModalOpen(false)}
          billboards={billboards}
          onConfirm={handleConfirm}
          briefId={briefId}
        />
      )}
      <Loader open={isLoading || isLoaderOpen} />
    </Box>
   
  );
};

export default AddCampaign;
