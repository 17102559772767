import axios from "../libs/axios.lib";
import * as loginUtils from "../utils/login.utils";

export async function deleteCampaignAPI(campaign_id) {
	const token = loginUtils.getUser().token;

	await axios.delete("/campaigns/" + encodeURIComponent(campaign_id), {
		headers: {
			Authorization: token,
		},
	});

	return true;
}

export async function createCampaignByBriefId(body = {
    briefId:null, billboards: ""}) {
    const token = loginUtils.getUser().token;

    const {data} = await axios.post("/campaigns/create-by-brief-id",
        body, {
        headers: { Authorization: token },
    });
    return data;
}

export async function createCampaignManually(body = {billboards: ""}) {
    const token = loginUtils.getUser().token;

    const {data} = await axios.post("/campaigns/create-manual",
        body, {
        headers: { Authorization: token },
    });
    return data;
}


export async function getCampaignListAPI() {
	const token = loginUtils.getUser().token;

	const { data } = await axios.get("/campaigns/campaigns", {
		headers: {
			Authorization: token,
		},
	});

	return data;
}

export async function fetchBillboardsByBrief(briefId) {
    if (!briefId) throw new Error("Brief ID is required");
    const token = loginUtils.getUser().token;
    const data  = await axios.get("campaigns/by-brief/" + encodeURIComponent(briefId), {
		headers: {
			Authorization: token,
		},
	});
    console.log(data)
    return data;
};


export async function getCampaignVisibilityData(campaignId) {
    const token = loginUtils.getUser().token;
 

  
    try {
      const response = await axios.get("campaigns/campaign-visibility-map/" + encodeURIComponent(campaignId), {
        headers: {
          Authorization: token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching visibility data:", error);
      return [];
    }
  }

  export async function getCampaignBillboardsAPI(campaignId) {
    if (!campaignId) throw new Error("Campaign ID is required");
    const token = loginUtils.getUser().token;
  
    const { data } = await axios.get(
      "/campaigns/" + encodeURIComponent(campaignId) + "/billboards",
      {
        headers: {
          Authorization: token,
        },
      }
    );
  
    return data;
  }
  

  export async function getBoxPlotDataAPI(payload) {
    const token = loginUtils.getUser().token;
  
    try {
      const { data } = await axios.post(
        "/campaigns/campaign-boxplot-data",
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return data;

      console.log(data);
    } catch (error) {
      console.error("Error fetching box plot data:", error);
      return [];
    }
  }
  

  export async function getlinegraphDataAPI(payload) {
    const token = loginUtils.getUser().token;
  
    try {
      const { data } = await axios.post(
        "/campaigns/campaign-linegraph-data",
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return data;

 
    } catch (error) {
      console.error("Error fetching box plot data:", error);
      return [];
    }
  }
  export async function downloadCampaignPPTAPI(campaignId) {
    const token = loginUtils.getUser().token;
  
    const response = await axios.get("/campaigns/" + encodeURIComponent(campaignId) + "/downloadcampaignppt", {
      headers: {
        Authorization: token,
      },
      responseType: "blob",
    });
  
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
  
    link.setAttribute("download", "presentation.pptx");
    document.body.appendChild(link);
    link.click();
  
    window.URL.revokeObjectURL(url);
  }