import React, { useState } from "react";
import { useParams } from "react-router-dom";
import LocationVisibilityLineChart from "./LocationVisibilityLineChart";
import LiveSpeedDurationBoxPlot from "./LiveSpeedDurationBoxPlot"; 
import { AppBar, Toolbar, Typography, Button, Box, Paper } from "@mui/material";

const CampaignGraphs = () => {
  const { campaignId } = useParams();
  const [selectedView, setSelectedView] = useState("location");

  return (
    <Box>
      {/* Header */}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Campaign {campaignId} Visibility Graph
          </Typography>
        </Toolbar>
      </AppBar>

      {/* View Selection Buttons */}
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2, my: 3 }}>
        <Button
          variant={selectedView === "location" ? "contained" : "outlined"}
          onClick={() => setSelectedView("location")}
          sx={{ borderRadius: "20px", px: 3 }}
        >
          Location vs Visibility
        </Button>
        <Button
          variant={selectedView === "boxplot" ? "contained" : "outlined"}
          onClick={() => setSelectedView("boxplot")}
          sx={{ borderRadius: "20px", px: 3 }}
        >
          Live Speed & Duration
        </Button>
        
      </Box>

      {/* Graph Display */}
      <Paper sx={{ p: 3 }}>
        {selectedView === "location" && <LocationVisibilityLineChart campaignId={campaignId} />}
        {selectedView === "boxplot" && <LiveSpeedDurationBoxPlot campaignId={campaignId} />}
        
      </Paper>
    </Box>
  );
};

export default CampaignGraphs;
