import React from "react";
import { Grid, Typography, Box, Paper, Divider } from "@mui/material";
import Plot from "react-plotly.js";

export default function HourlyVisibilityLineGraph({ data }) {
  return (
    <Box mt={3} sx={{ width: "100%" }}>
      {Object.entries(data).map(([billboardId, values]) => {
        const hours = Object.keys(values.hourly_data);
        const minDurations = hours.map((hour) => values.hourly_data[hour].min);
        const maxDurations = hours.map((hour) => values.hourly_data[hour].max);

        return (
          <Grid container justifyContent="center" key={billboardId}>
            <Grid item xs={12} md={10}>
              <Paper
                elevation={4}
                sx={{
                  p: 3,
                  mb: 4,
                  borderRadius: 3,
                  backgroundColor: "#f9f9f9",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ mb: 2, color: "primary.main", fontWeight: 600 }}
                >
                  ID: {billboardId}, {values.location}, {values.height} X{" "}
                  {values.width}
                </Typography>
                <Divider sx={{ mb: 2 }} />

                <Box
                  sx={{
                    backgroundColor: "#fff",
                    p: 2,
                    borderRadius: 2,
                    boxShadow: 2,
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, color: "grey.700" }}
                  >
                    Hourly Visibility Duration
                  </Typography>
                  <Plot
                    data={[
                      {
                        x: hours,
                        y: minDurations,
                        type: "scatter",
                        mode: "lines+markers",
                        name: "Min Duration",
                        line: { color: "#d32f2f" },
                      },
                      {
                        x: hours,
                        y: maxDurations,
                        type: "scatter",
                        mode: "lines+markers",
                        name: "Max Duration",
                        line: { color: "#2e7d32" },
                      },
                    ]}
                    layout={{
                      autosize: true,
                      margin: { t: 20, l: 50, r: 20, b: 50 },
                      xaxis: { title: "Time (Hour)", tickangle: -45 },
                      yaxis: { title: "Visibility Duration (s)" },
                      paper_bgcolor: "#fff",
                      plot_bgcolor: "#fff",
                    }}
                    useResizeHandler={true}
                    style={{ width: "100%", height: "400px" }}
                    config={{ displayModeBar: false, responsive: true }}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
}
