import React from "react";
import { Grid, Typography, Box, Paper, Divider } from "@mui/material";
import Plot from "react-plotly.js";

export default function SpeedDurationBoxPlot({ data }) {
  return (
    <Box mt={3}>
      {Object.entries(data).map(([billboardId, values]) => (
        <Paper
          key={billboardId}
          elevation={4}
          sx={{
            p: 3,
            mb: 4,
            borderRadius: 3,
            backgroundColor: "#f9f9f9",
          }}
        >
          <Typography
            variant="h6"
            sx={{ mb: 2, color: "primary.main", fontWeight: 600 }}
          >
            ID: {billboardId}, {values.location}, {values.height} X {values.width}

          </Typography>
          <Divider sx={{ mb: 2 }} />

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  p: 2,
                  borderRadius: 2,
                  boxShadow: 2,
                }}
              >
                <Typography variant="subtitle1" sx={{ mb: 1, color: "grey.700" }}>
                  Speed Box Plot
                </Typography>
                <Plot
                  data={[
                    {
                      y: values.speeds,
                      type: "box",
                      name: "Speed",
                      marker: { color: "#1976d2" }, // primary blue
                    },
                  ]}
                  layout={{
                    margin: { t: 20, l: 40, r: 20, b: 20 },
                    height: 300,
                    paper_bgcolor: "#fff",
                    plot_bgcolor: "#fff",
                  }}
                  config={{ displayModeBar: false }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  p: 2,
                  borderRadius: 2,
                  boxShadow: 2,
                }}
              >
                <Typography variant="subtitle1" sx={{ mb: 1, color: "grey.700" }}>
                  Duration Box Plot
                </Typography>
                <Plot
                  data={[
                    {
                      y: values.durations,
                      type: "box",
                      name: "Duration",
                      marker: { color: "#2e7d32" }, // green
                    },
                  ]}
                  layout={{
                    margin: { t: 20, l: 40, r: 20, b: 20 },
                    height: 300,
                    paper_bgcolor: "#fff",
                    plot_bgcolor: "#fff",
                  }}
                  config={{ displayModeBar: false }}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </Box>
  );
}
