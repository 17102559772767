import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { getCampaignVisibilityData } from "../../apis/campaigns.apis";
import { Paper, Typography, Box } from "@mui/material";

const AssetVisibilityLineChart = ({ campaignId }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const result = await getCampaignVisibilityData(campaignId);
        const formattedData = result.map((item) => ({
          ...item,
          label: `${item.location} ( ${item.height} X ${item.width} )`, // 👈 Combine location & size
        }));
        setData(formattedData);
        toast.success("Graph plotted successfully!");
      } catch (error) {
        toast.error("Failed to plot visibility graph!");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [campaignId]);

  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 2, backgroundColor: "#f5f5f5" }}>
      <Typography variant="h6" textAlign="center" mb={2} fontWeight="bold">
        Asset-wise Visibility Duration
      </Typography>
      <Loader open={isLoading} />
      <Box sx={{ width: "100%", height: 600 }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{ top: 20, right: 30, left: 50, bottom: 100 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#ddd" />
            <XAxis
              dataKey="label" // 👈 Use combined label
              angle={-45}
              textAnchor="end"
              height={120}
              interval={0}
              tick={{ fontSize: 12 }}
            />
            <YAxis
              label={{
                value: "Visibility Duration",
                angle: -90,
                position: "insideLeft",
                fontSize: 14,
                fontWeight: "bold",
              }}
              tick={{ fontSize: 12 }}
            />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <Line
              type="monotone"
              dataKey="min_visibility_duration"
              stroke="red"
              name="Min Visibility"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="max_visibility_duration"
              stroke="green"
              name="Max Visibility"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="avg_visibility_duration"
              stroke="blue"
              name="Avg Visibility"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

export default AssetVisibilityLineChart;
